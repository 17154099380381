.notion-callout {
  align-items: flex-start;
}

.notion-page-header {
  display: none;
}

.notion-nav-breadcrumbs {
  display: none;
}

.notion-page-offset {
  margin-top: 72px
}

.notion b {
  font-weight: 700;
}

.notion-column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
  /* border: 0.5px solid #C0C0C0; */
  border-radius: 3px;
}
